<template>
  <div class="">
  <!-- 顶部 -->
  <topheader :type="activeIndex" @refresh="refresh"></topheader>
  <div class="findlistindex">
    <div class="common_page_title">
      <div class="guwidth">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/zhaolvshi' }"
            >找律师首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>律师列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 按类型/地区找律师 -->
    <div class="findlsbox base_w flex">
      <div class="common_index_leftwidth">
        <div class="common_title_box">
          <span class="title">按类型查找</span>
        </div>
        <!-- <div class="typebox flexbox">
          <p>全部类型</p>
          <span v-for="i in 15" :key="i">婚姻家事</span>
        </div> -->
        <div class="typebox flexbox">
          <span
            @click.stop="typeFun(-1)"
            :class="[navactive == -1 ? 'active' : '']"
            >全部类型</span
          >
          <span
            v-for="(item, i) in LawKnowledgelist"
            :key="i"
            @click.stop="typeFun(i, item)"
            :class="[navactive == i ? 'active' : '']"
            >{{ item.name }}</span
          >
        </div>
        <!-- <div class="subtext">
          <p class="text1">按地区查找</p>
        </div> -->
        <div class="subtext">
          <div class="text1 flexbox">
            <span> 按地区查找</span>
            <el-cascader
              v-model="Dataval"
              :options="CityInfo"
              :props="TagProps"
              placeholder="全国"
              @change="handleChange"
            >
            </el-cascader>
          </div>
        </div>
        <div class="lvsbox flexbox">
          <div
            class="lvsitem flexbox"
            v-for="(item, i) in Lawlist"
            :key="i"
            @click.stop="tourl('/lvdetails?id=' + item.id)"
          >
            <img :src="item.photo" alt="" class="lsimg" />
            <div class="lsright">
              <p class="text1">{{ item.lawyerName }}律师</p>
              <div class="text2">
                好评率：<span>{{ item.haopinhlv }}%</span>接单量:
                <span>{{ item.jiedanums }}次</span>
              </div>
              <p class="text3 flexbox">
                <span v-for="(data, index) in item.goodsIdsArr" :key="index">{{
                  data
                }}</span>
              </p>
              <p class="text4">{{ item.addr }}</p>
            </div>
          </div>

          <div class="paginationblock">
            <el-pagination
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :current-page.sync="pagination.currentPage"
              :page-size="pagination.size"
              layout="total, prev, pager, next"
              :total="pagination.total"
            >
            </el-pagination>
          </div>

          <NoData v-if="Lawlist.length <= 0"></NoData>
          
        </div>
      </div>
      <div class="common_index_rightwidth">
        <guanggao :type="1"></guanggao>
        <!-- 律师最新回复 -->
        <div class="lshfbox">
          <div class="common_title_box">
            <span class="title">律师最新回复</span>
          </div>
          <div class="lshfmainbox">
            <div class="mainitem" v-for="(item, i) in wentypedalist" :key="i">
              <div class="itemlsbox flex" v-if="item.lsLawyer">
                <div
                  class="itemlsboxleft flex"
                  @click.stop="tourl('lvdetails?id=' + item.id)"
                >
                  <img :src="item.lsLawyer.photo" alt="" class="lsimg" />
                  <div class="lsinfo flex_column_between">
                    <p class="text1">{{ item.lsLawyer.lawyerName }}律师</p>
                    <div class="text2">
                      好评率：<span class="mr15"
                        >{{ item.lsLawyer.haopinhlv }}%</span
                      >
                      接单量: <span>{{ item.lsLawyer.jiedanums }}次</span>
                    </div>
                  </div>
                </div>
                <!-- <p
                  class="itemlsboxright"
                  v-if="item.lsFuwuOrderMsgs[0].createTime"
                >
                  {{ item.lsFuwuOrderMsgs[0].createTime | fifterTime }}
                </p> -->
              </div>

              <div
                class="zixuntop flex_topstart"
                @click.stop="tourl('/wendetails?id=' + 1)"
              >
                <img src="@/assets/img/wen.png" alt="" />
                <span class="">{{ item.content }}</span>
              </div>
              <!-- 律师答 -->
              <div class="dabox" v-if="item.lsFuwuOrderMsgs.length > 0">
                <div class="daitem">
                  <div class="topdaitem flex_topstart">
                    <img src="@/assets/img/da.png" alt="" />
                    <span class="" v-if="item.lsFuwuOrderMsgs.length > 0">
                      {{ item.lsFuwuOrderMsgs[0].content }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="btnbox" @click.stop="tourl('/toask')">去提问</div>
          </div>
          <!-- ============ -->
        </div>

        <!-- 法律资讯 -->
        <div class="flzxbox">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="newsbox">
            <news :type="2" :list="informationlist"></news>
          </div>

          <div class="zixunbo" @click.stop="tourl('/toask')">
            <div class="zaixian">在线咨询 >> </div>
            <img
              src="@/assets/img/icon_guang1.png"  alt="" style="width: 586px; height: 330px; border-radius: 13px; margin-top: 24px; "
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 热门法律标签 -->
    <div class="hotlabel base_w">
      <div class="common_title_box">
        <span class="title">热门法律标签</span>
      </div>
      <div class="labelbox flexbox">
        <span v-for="(item, i) in LawKnowledgelist" :key="i">{{
          item.name
        }}</span>
      </div>
    </div>
  </div>
  <!-- 页脚 -->
  <bottomfooter></bottomfooter>
</div>
</template>

<script>
import resetTime from '@/util/check.js'
import apiUrl from '@/api/main'
import Dataoptions from '@/util/Data'

import guanggao from '@/components/guanggao'
import news from '@/components/news'
import NoData  from '@/components/NoData'

import topheader from '@/components/TopHeader.vue'
import bottomfooter from '@/components/Footer.vue'
export default {
  components: { guanggao, news,topheader ,bottomfooter,NoData },
  data() {
    return {
      activeIndex: '4',
      navactive: -1,
      typename: '',
      chooseCityText: '',
      // 地区
      Dataval: [],
      CityInfo: Dataoptions,
      provinceVal:'',
      cityVal:'',
      // 更改组件默认的数据键
      TagProps: {
        value: 'label',
        label: 'label',
        children: 'children'
      },
      Lawlist: [],
      wentypedalist: [], // 问答列表
      informationlist: [], // 资讯
      LawKnowledgelist: [], // 标签
      pagination: {
        size: 10, // 每页几条
        currentPage: 1,
        total: 0
      }
    }
  },
  filters: {
    fifterTime(value) {
      let time = value ? value.replace(/-/g, '/') : ''
      if (time) {
        return resetTime.timeago(new Date(time).getTime())
      }
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 查找律师
    if(this.$route.query.typename){
      this.typename = this.$route.query.typename;
    }
    if(this.$route.query.chooseCityText && this.$route.query.provinceVal && this.$route.query.cityVal ){
      this.chooseCityText = this.$route.query.chooseCityText;
      this.Dataval = [this.$route.query.provinceVal, this.$route.query.cityVal];
    }
    this.lsLawyerlist();
    this.gettypewendalist() //获取问答列表
    this.getlsLawKnowledgelist() // 律师标签
    this.getinformationlist() //  获取法律资讯
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
    refresh(){
      this.Lawlist = [];
      this.pagination = {
        size: 10, 
        currentPage: 1,
        total: 0
      }
      this.lsLawyerlist();

    },
   
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    // 省市区三级联动 按地区查找律师
    handleChange(value) {
      console.log(value)
      if (value[0] == '全国') {
        this.chooseCityText = ''
      } else {
        this.chooseCityText = value[0] + value[1]
      }
      this.lsLawyerlist()
    },
    // 点击类型标签 按类型查找律师
    typeFun(index, item) {
      // 全部类型
      if (index == -1) {
        this.navactive = -1
        this.typename = ''
      } else {
        this.navactive = index
        this.typename = item.name
      }
      this.lsLawyerlist()
    },
    // 获取律师列表
    lsLawyerlist() {
      let data = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.size,
        orderByColumn: ' ',
        isAsc: 'desc',
        addr: this.chooseCityText, // 地区
        lawyerName: this.$route.query.searchname || '',  // 律师姓名
        goodsIds: this.typename
      }
      apiUrl.lsLawyerindex(data).then((res) => {
        res.rows.forEach((element) => {
          if (element.goodsIds) {
            element.goodsIdsArr = element.goodsIds.split(',')
          }
        })

        this.$nextTick(()=>{
          this.Lawlist = res.rows;
          this.pagination.total = res.total;
          console.log( this.Lawlist)
        })
        
      })
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.lsLawyerlist()
    },
    // 获取问答列表
    gettypewendalist() {
      let data = {
        pageSize: 2,
        pageNum: 1
      }
      apiUrl.wendalist(data).then((res) => {
        this.wentypedalist = res.rows
      })
    },
    // 获取法律资讯
    getinformationlist() {
      let data = {
        userId: localStorage.getItem('userId'),
        pageSize: 3,
        pageNum: 1
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.informationlist = res.rows
      })
    },
    // 律师擅长标签
    getlsLawKnowledgelist() {
      let data = {
        pId: 0,
        userId: localStorage.getItem('userId')
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
        this.LawKnowledgelist = res.rows;
        if(this.$route.query.typename){
            res.rows.forEach((element,i) => {
                if(element.name == this.$route.query.typename){
                  this.navactive = i;
                }
            });
          }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.findlistindex {
  // 按类型/地区找律师
  .findlsbox {
    padding-top: 33px;
    padding-bottom: 40px;
    border-bottom: 3px solid #333333;
    .common_index_leftwidth {
      .typebox {
        flex-wrap: wrap;
        margin-top: 33px;
        p {
          background: #305bfe;
          border-radius: 21px;
          padding: 7px 16px;
          font-size: 18px;
          color: #ffffff;
          margin-right: 12px;
          margin-bottom: 12px;
        }
        span {
          padding: 7px 16px;
          font-size: 18px;
          border: 1px solid #cccccc;
          color: #333333;
          cursor: pointer;
          border-radius: 21px;
          margin-right: 12px;
          margin-bottom: 12px;
        }
        span:hover {
          color: #305bfe;
          border: 1px solid #305bfe;
        }
        span.active {
          background: #305bfe;
          color: #ffffff;
        }
      }
      .subtext {
        margin-top: 21px;
        margin-bottom: 28px;
        .text1 {
          font-size: 20px;
          font-weight: 500;
          color: #333333;
          margin-right: 13px;
          span {
            padding-right: 15px;
            width:100px;
            font-size: 20px;
            color: #333333;
            font-weight: 500;
          }
        }
        .el-cascader{
          width:200px !important;
          border: 1px solid #ccc;
        }
        .el-cascader .el-input{
          width:200px !important;
        }
      }
      .lvsbox {
        flex-wrap: wrap;
        .lvsitem {
          width: 403px;
          // height: 163px;
          background: #ffffff;
          border-radius: 10px;
          border: 1px solid #e6e6e6;
          margin-right: 24px;
          margin-bottom: 24px;
          padding: 16px;
          .lsimg {
            width: 124px;
            height: 164px;
            border-radius: 16px;
          }
          .lsright {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            min-height: 137px;
            margin-left: 13px;
            .text1 {
              font-size: 20px;
              font-weight: 500;
              color: #333333;
            }
            .text2 {
              font-size: 16px;
              color: #333333;
              span {
                color: #305bfe;
                margin-right: 13px;
              }
            }
            .text3 {
              flex-wrap: wrap;
              span {
                padding: 3px 6px;
                border-radius: 12px;
                border: 1px solid #cccccc;
                font-size: 13px;
                color: #333333;
                margin-right: 7px;
                margin-bottom: 4px;
              }
              span:nth-child(3n) {
                margin-right: 0px;
              }
            }
            .text4 {
              font-size: 16px;
              color: #666666;
            }
          }
        }
        .lvsitem:nth-child(2n) {
          margin-right: 0px;
        }
        .lvsitem:hover {
          cursor: pointer;
          border: 1px solid #305bfe;
        }
        .more {
          // margin-top: 33px;
          font-size: 16px;
          color: #777777;
          margin-right: 10px;
          img {
            width: 6px;
            height: 11px;
          }
        }
      }
    }

    .common_index_rightwidth {
      .lshfbox {
        padding-top: 24px;
        .common_title_box {
          padding-bottom: 16px;
        }
      }
      // =========
      .lshfmainbox {
        // width: 488px;
        // min-height: 708px;
        width: 586px;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 0 27px 27px 27px;
        .mainitem {
          padding-top: 27px;
          padding-bottom: 27px;
          border-bottom: 1px solid #e6e6e6;
        }
        .itemlsbox {
          justify-content: space-between;

          .itemlsboxleft {
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 13px;
            }
            .lsinfo {
              height: 40px;
              .text1 {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
              }
              .text2 {
                font-size: 16px;
                color: #333333;
                span {
                  color: #305bfe;
                }
              }
            }
          }
          .itemlsboxright {
            font-size: 12px;
            color: #777777;
          }
        }

        .zixuntop {
          margin-top: 20px;
          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
          span {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            word-break: break-all;
          }
        }
        .dabox {
          margin-top: 17px;
          width: 423px;
          .daitem {
            background: #f5f5f5;
            border-radius: 0px 10px 10px 10px;
            background: #ececec;
            .topdaitem {
              padding: 13px;
              img {
                width: 18px;
                height: 18px;
                margin-right: 10px;
              }
              span {
                display: inline-block;
                font-size: 16px;
                color: #333333;
                line-height: 20px;
              }
            }
          }
        }

        .btnbox {
          width: 184px;
          height: 48px;
          line-height: 48px;
          border-radius: 36px;
          margin: 23px auto 0;
          font-size: 18px;
        }
      }
      // =======

      // 法律资讯
      .flzxbox {
        margin-top: 24px;
        .common_title_box {
          margin-bottom: 16px;
        }
        .newsbox {
          // width: 488px;
          width: 586px;
          background: #f8f8f8;
          border-radius: 10px;
          padding: 27px;
        }
      }
    }
  }

  // 热门法律标签
  .hotlabel {
    padding-top: 33px;
    padding-bottom: 20px;
    .common_title_box {
      margin-bottom: 33px;
    }
    .labelbox {
      flex-wrap: wrap;
      span {
        border-radius: 18px;
        border: 1px solid #cccccc;
        padding: 9px 32px;
        font-size: 16px;
        color: #333333;
        cursor: pointer;
        margin-right: 16px;
        margin-bottom: 24px;
      }
      span:hover {
        color: #305bfe;
        border: 1px solid #305bfe;
      }
    }
  }

  // 分页
  .fenyebox {
    width: 691px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>